import '../styles.css';

const NotFoundPage = () => {
    return (
        <main>
            <h1>404: Unavailable 😿</h1>
        </main>
    );
}

export default NotFoundPage;
